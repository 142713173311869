.App {
  max-width: 100%;
}

.flex-footer-fix {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mid-container {
  flex: 1;
}

.mid-container-idea {
  display: flex;
  flex: 1;
  padding: 20px 0 50px 0;
}

.fieldset {
  display: grid;
  grid-template-columns: 1fr 3fr; 
  gap: 1rem;
}

/* fieldset {
  display: flex;
  flex-wrap: wrap;
  border-radius: 10px;
  border: none;
  background: #39af54;
  color: white;
  padding: 30px;
} */
/* 
fieldset div {
  display: inline-block;
} */

.formLeft {
  flex: 1 1 auto;
}


.formRight {
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;
}

.formRight p {
  margin: 0 0 13px 0;
}

#swippper {
  flex: 0 0 100%;
}

.swiper-slide {
  background: #000;
  align-items: unset;
}

textarea {
  width: 80%;
  height: 100px;
}

:focus {
  outline: none;
  box-shadow: 0 0 0 1px #39af54, 0 0 0 2px white;
}

.swiperIdea {
  float: left;
}

.ideaInfo {
  padding: 30px;
}

.ideaInfo h2 {
  display: inline-block;
}

.main-1-left {
  background: #39af54;
  flex: 0 1 auto;
}

.main-1-right {
  flex: 0 1 100%;
}

#root > div > div > main > div:nth-child(1) > div > div.main-1-right > img {
  width: 100%;
}

/* Image items in form */

.image-item {
  position: relative;
  background: #000;
  /* width: 50%; */
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.image-item__btn-wrapper {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.image-item:hover .image {
  opacity: 0.3;
}

.image-item:hover .image-item__btn-wrapper {
  opacity: 1;
}

.text {
  color: white;
  font-size: 16px;
  padding: 16px 32px;
}

.display-linebreak {
  white-space: pre-line;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .ka {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
} */

/* .ka-table-wrapper {
  width: 80%;
  border: 1px red solid;
} */

/* .ka-table {
  table-layout: fixed;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ka-thead,
.ka-tr,
.ka-thead-row {
  width: 100%;  
} */

body {
  margin: 0;
  background-color: #e6e7e8;
  font-family: Arial, Helvetica, sans-serif;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
}

nav {
  display: flex;
  min-height: 100px;
  width: 100%;
  color: #414042;
  align-items: center;
  justify-content: center;
}

.nav-align {
  width: 80%;
  margin: auto;
}

.logo {
  cursor: pointer;
  margin-top: 16px;
  float: left;
  color: #414042;
}

.logo:hover strong {
  color: #39af54;
}

.logo div:nth-child(1) {
  font-size: 25px;
}

.logo div:nth-child(2) {
  font-weight: bold;
  letter-spacing: 0.45px;
}

body > nav > div > div.logo > div:nth-child(2) > span > img {
  height: 16px;
  filter: grayscale(1);
}

.navigation {
  display: flex;
  float: right;
  color: #414042;
}

nav ul {
  padding: 0;
}

nav ul li {
  display: inline-block;
  font-weight: bold;
  font-size: 20px;
  padding: 0px 0 0px 20px;
}

nav ul li a {
  text-decoration: none;
  color: #414042;
  transition: box-shadow 0.5s;
  box-shadow: 0px 0px 0px 3px white;
  padding: 0.4em 0.8em;
  border-radius: 12px;
}

nav ul li a:hover {
  box-shadow: 0px 0px 0px 6px white;
}

.logoff {
  font-size: 20px;
  padding: 0.4em 0.8em;
  background: transparent;
  border: 2px white solid;
  border-radius: 12px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: 0.5s;
}

.logoff:hover {
  box-shadow: 0px 0px 0px 6px white;
  background: white;
  color: #39af54;
}

/* MAIN  */

main {
  width: 100%;
}

.main-1 {
  display: flex;
  margin: auto;
  width: 80%;
  color: white;
  overflow: hidden;
}

.main-1 > div:nth-child(1) {
  padding: 50px;
  float: left;
}

.main-1 > div:nth-child(2) {
  float: right;
}

.main-1 > div:nth-child(2) img {
  vertical-align: middle;
}

.main-1 div h1 {
  margin: 0;
  font-size: 40px;
  font-weight: 900;
}

.main-1 div p {
  line-height: 30pt;
  font-size: 22px;
  max-width: 1000px;
  margin: 25px 0 25px 0;
}

.main-1 button {
  font-size: 1.4rem;
  padding: 15px;
  background: transparent;
  border: 2px white solid;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  margin-right: 15px;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 5px;
}

.main-1 button:hover {
  background: white;
  color: #39af54;
}

.fieldset button {
  font-size: 22px;
  padding: 15px;
  background: transparent;
  border: 2px white solid;
  border-radius: 10px;
  font-weight: bold;
  color: #414042;
  margin-right: 15px;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 5px;
  width: 100%;
}

.fieldset button:hover {
  background: white;
  color: #39af54;
}

#swippper button{
  color: white;
}

#swippper button:hover {
  color: #39af54;
}

/* 2ND MAIN */

.main-2 {
  flex-wrap: wrap;
  display: flex;
  min-height: 1080px;
  width: 100%;
  background: #e6e7e8;
  color: #333132;
  justify-content: center;
}

.main-2 > div:nth-child(1) {
  width: 80%;
}

.main-2 div h1 {
  margin: 0;
  padding: 150px 0 0 0;
  font-size: 40px;
  font-weight: 900;
}

.main-2 div p {
  line-height: 40pt;
  font-size: 25px;
  max-width: 1000px;
}

.main-2 > div:nth-child(2) {
  width: 80%;
}

.articles {
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
  padding-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  justify-content: space-between;
  /* flex-wrap: wrap; Media-querry*/
}

.articles article {
  background: white;
  height: 270px;
flex: 0 1 460px;
  transition: 0.5s;
}

.articles article:nth-child(1) {
  position: relative;
  background-size: 100%;
  background-image: url(./../imgs/HMakers_thumb.jpg);
}

.articles article:nth-child(2) {
  position: relative;
  background-size: 100%;
  background-image: url(./../imgs/na_mladite_thumb.jpg);
}

.articles article:nth-child(3) {
  position: relative;
  background-size: 100%;
  background-image: url(./../imgs/onrock_thumb.jpg);
}

.hero-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.articles button {
  font-size: 15px;
  padding: 10px;
  background: transparent;
  border: 2px white solid;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  cursor: pointer;
  transition: 0.5s;
}

.articles button:hover {
  background: white;
  color: #333132;
}

.enroll-articles {
  width: 80%;
  display: flex;
}

.enroll-articles h2 {
  font-size: 30px;
  font-weight: 900;
  margin-top: 200px;
}

.enroll-articles p {
  margin: 0;
  padding: 25px 60px 35px 0;
}

.enroll-articles button {
  font-size: 22px;
  padding: 15px 25px 15px 25px;
  background: #39af54;
  border: 2px white solid;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  margin-right: 15px;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 200px;
}

.enroll-articles button:hover {
  color: #39af54;
  background: transparent;
  border-color: #39af54;
}

/* FOOTER */

.footerdiv {
  width: 80%;
  color: white;
  display: flex;
  justify-content: space-between;
}

.footerdiv div ul {
  padding: 10px 15px;
  margin: 0;
}

.footerdiv h4 {
  padding: 0;
  margin: 0;
  font-weight: 800;
}

.footerdiv div ul li {
  line-height: 22pt;
}

footer {
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  width: 100%;
  background: #333132;
  bottom: 0;
  /* background: rgb(0, 166, 135);
  background: linear-gradient(90deg, rgba(0, 166, 135, 1) 0%, rgba(48, 170, 0, 1) 100%); */
}

footer a {
  padding: 5px 50px 0 50px;
  font-size: 5rem;
  font-weight: 900;
  height: 130px;
  color: white;
  border: 4px solid white;
  border-radius: 25px;
  background: transparent;
  transition: 0.5s;
  cursor: wait;
  text-decoration: none;
}

footer a:hover {
  color: #16a98a;
  background: white;
}

/* Phones */

@media screen and (max-width: 800px){

  nav ul li {
    display: block;
    padding: unset;
  }

  nav ul li a {
    display: block;
    background: white;
    margin: 20px 0;
    text-align: center;
    color: #414042;
    transition: box-shadow 0.5s;
    box-shadow: unset;
    padding: 0.6em 0.8em;
  }

  .logoff {
    width: 100%;
    font-size: 20px;
    padding: 0.6em 0.8em;
    background: transparent;
    border: 2px white solid;
  }

  .navigation {
    display: unset;
    float: unset;
    clear: both;
    justify-content: center;
  }
  
  .logo {
    float: unset;
    text-align: center;
  }

  .main-1 {
    display: block;
    text-align: center;
  }

  .main-1 > div:nth-child(1) {
    padding: 15px;
  }

  .main-1 button {
    margin-right: 0;
  }

  .main-2 {
    text-align: center;
  }

  .articles {
    grid-template-columns: 1fr;
  }
  
  .articles article {
    margin-top: 20px;
    min-width: 100%;
  }


  .enroll-articles {
    flex-direction: column;
  }

  .enroll-articles p {
    padding: unset;
    padding-bottom: 20px;
  }

  .enroll-articles button{
    margin: 0 0 30px 0;
  }

  .enroll-articles h2 {
    margin: 100px 0 25px 0;
  }

  .main-1 div h1,
  .main-2 div h1 {
    text-align: center;
    font-size: 1.75rem;
  }

  .main-1 div p,
  .main-2 div p {
    line-height: 30pt;
  }
  
}