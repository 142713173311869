.login-wrapper,
.register-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: white;
    text-align: center;
    z-index: 5;
    min-width: 250px;
    max-height: 450px;
}

.login-window,
.register-window {
    background: #414042;
    padding: 40px;
    margin: auto;
    border-radius: 15px;
}

.register-wrapper {
    max-height: 550px;
    z-index: 6;
}

.close-link {
    cursor: pointer;
}

.close-link:hover {
    color: #39af54;
}

.login-error {
    color: #414042;
    background: hsl(59, 100%, 63%);
    margin-top: 10px;
    padding: 10px;
    border-radius: 10px;
    min-width: 250px;
}

.login-error p {
    margin: 0;
}

.login-wrapper div,
.register-wrapper div {
    display: inline-block;
}

fieldset button,
.login-wrapper button,
.register-wrapper button {
    color: white;
    background: transparent;
    padding: 10px 10px;
    border: 1px white solid;
    border-radius: 5px;
    width: 200px;
    box-sizing: border-box;
    transition: 0.5s;
}

fieldset button:hover,
fieldset button:focus,
.login-wrapper button:hover,
.login-wrapper button:focus,
.register-wrapper button:hover,
.register-wrapper button:focus {
    cursor: pointer;
    background: white;
    color: #414042;
}

input:autofill {
    background: white;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active {
    box-shadow: none;
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input, 
textarea,
select,
.register-wrapper input,
.register-wrapper textarea {
    width: 100%;
    box-sizing: border-box;
    background-color: white;
    min-height: 20px;
    min-width: 200px;
    padding: 5px;
    text-align: center;
    border: 2px white solid;
    border-radius: 5px;
}

#projectForm input,
#projectForm textarea,
#projectForm select {
    text-align: left;
}

textarea {
    resize: vertical;
}

/* Hide/Show magic */
.hide {
    display: block;
    transition: 800ms;
    opacity: 1;
}

.show {
    transform: translateY(100px) scale(0);
    opacity: 0;
}